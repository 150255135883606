<script setup lang="ts">
import { CmsBlockText } from '@shopware-pwa/composables-next';

const props = defineProps<{
  content: CmsBlockText;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const content = getSlotContent('content');
</script>
<template>
  <div class="cms-block-text">
    <CmsGenericElement :content="content" />
  </div>
</template>
